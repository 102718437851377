<style type="text/css">
@import "~@/assets/css/common.css";
</style>
<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import { getWebNoticePage, deleteWebNotice } from "@/api/notice/notice.js";
import { tipsList, tipsInfo, tipsRead, tipsReadAll } from "@/api/index";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "系统消息",
      items: [
        {
          text: "用户首页",
          href: "/admin",
        },
        {
          text: "系统消息",
          active: true,
        },
      ],
      pageData: { pageNum: 1, pageSize: 10, total: 0 ,ydzt:1},
      tipsList: [],
      tipsInfo: null,
      tipShow: false,
    };
  },
  methods: {
    // handleSizeChange(val) {
    //   this.pageData.pageSize = val;
    //   this.getList();
    // },
    // handleCurrentChange(val) {
    //   this.pageData.pageNum = val;
    //   this.getList();
    // },
    getList() {
      tipsList(this.pageData).then((res) => {
        if (res.status) {
          this.tipsList = res.data;
          this.pageData.total = res.total;
        }
      });
    },
    deleteNotice(nid) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteWebNotice(nid).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getList();
          }
        });
      });
    },
    messageInfo(index) {
      let noticeId = this.noticeList[index].noticeId;
      this.noticeList[index].sfyd = true;
      this.$yzNotice().show(noticeId);
    },
    tipsClick(index) {
      let data = this.tipsList[index];
      tipsInfo(data.tid).then((res) => {
        if (res.status) {
          this.tipsInfo = res.data;
          this.tipShow = true;
          tipsRead(data.tid);
        }
      });
    },
    closeModal() {
      this.tipShow = false;
    },
  },
  mounted() {
    this.getList();
  },
  watch:{
     'pageData.ydzt':{
         handler(val){
             this.getList()
         }
     } 
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 165px">
          <div v-if="tipsList">
            <div class="card-body">
              <h4 class="card-title pb-3 border-dash">推送通知</h4>
              <div>
                <el-radio-group v-model="pageData.ydzt">
                  <el-radio :label="0">未读</el-radio>
                  <el-radio :label="1">已读</el-radio>
                </el-radio-group>
              </div>
              <div
                class="d-flex border-dash p-2"
                v-for="(v, i) in tipsList"
                :key="i"
              >
                <!-- <span style="width:10px">
                  <el-badge is-dot class="" v-if="!v.sfyd"></el-badge>
                </span> -->
                <img
                  src="@/assets/images/person/stLine-talk-l.png"
                  class="sys-img mr-2"
                  alt=""
                />
                <div>
                  <!-- <a
                    href="javascript:;"
                    @click="$yzNotice().show(v.noticeId)"
                    v-if="isAdmin === 1"
                  >
                    <div class="text-dark">{{ v.noticeTitle }}</div>
                  </a> -->
                  <a href="javascript:;" @click="tipsClick(i)">
                    <div class="text-dark">{{ v.bt }}</div>
                  </a>
                  <div class="message-bottom">
                    <span class="grey-time">{{
                      v.createTime.substring(0, 16)
                    }}</span
                    ><span class="ml-2">系统推送</span>
                    <!-- <i
                      class="iconfont message-delete icon-riLine-delete-bin-line"
                      style="cursor: pointer"
                      @click="deleteNotice(v.noticeId)"
                    ></i> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- <div style="text-align: center" class="pb-4">
              <el-pagination
                @size-change="handleSizeChange"
                background
                @current-change="handleCurrentChange"
                :current-page="pageData.pageNum"
                :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next"
                :total="pageData.total"
              >
              </el-pagination>
            </div> -->
          </div>
          <div
            v-else
            class="d-flex justify-content-center align-items-center"
            style="font-sizes: 20px; height: 100px"
          >
            暂无推送消息
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-if="tipsInfo"
      v-model="tipShow"
      id="importXL"
      centered
      :title="tipsInfo.bt"
      size="lg"
      title-class="font-18"
    >
      <div class="mb-2 tips-content" style="margin-top: -10px">
        <div v-html="tipsInfo.nr"></div>
      </div>
      <div class="border" slot="modal-footer">
        <button type="button" class="btn btn-primary" @click="closeModal">
          确定
        </button>
      </div>
    </b-modal>
  </Layout>
</template>
